.SVA {
  text-align: center;
  background-color: rgb(255, 255, 255);
}

.SVAbanner {
  position: relative;
  height: 40vh;
  overflow: hidden;
}

.SVAbanner img {
  width: 100%;
  height: 100%;
}