.sideBarFilterContainer{ 
    display: grid;
    grid-template-columns: 14fr 1fr;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    overflow-x: hidden;
    transition: .3s;
  }
  /* FIXME rename later when adjusted */
  .sideBarFilters {
    background-color: #ffffff;
    width: 100%;
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-template-rows: 6vh auto;
  }

  .filtersComponent {
    grid-row: 2;
    grid-column: 1 / 3;
    padding: 4vw;
  }

  .closeSideBar{
    width: 0%;
    background-color: rgba(0, 0, 0, 0);
  }
  
  .closeTopFilter{
    grid-row: 1;
    grid-column: 2;
    float: right;
    font-size: 24px;
    margin-right: 2px;
    border-style: hidden;
    background-color: transparent;
    position: sticky;
    top: 0;
  }

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: solid black;
    border-width: 3px;
    border-radius: 5px;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #eee;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 8px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 5px 5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .calendarStyle {
    background-color: red;
  }