
.mentalHealthBanner {
  position: relative;
  height: 30vh;
  overflow: hidden;
  background-image: url("../home/banner/dogtags_blank.png"); 
  background-size: cover;
  background-position: center;
  margin: 0%;
}

.mentalHealthLatinbox {
  background-color: rgb(189, 189, 189);
  margin: 0%;
  padding: 0%;
  text-align: center;
  font-size: larger;
  }

  .mentalHealthSenctenceLinks {
    padding: 1%;
  }

  .backToHomeLink {
    display: block;
    font-family: 'Rubik', sans-serif;
    border-radius: 100px;
    padding: 1em;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    text-align: center;
    color: rgb(0, 0, 0);
    background: linear-gradient(52deg, rgba(189, 189, 189,1) 0%, rgba(189, 189, 189,1) 200%);
    border-style: none;
    margin: 1vh 38vw 15vh 38vw;
}

@media screen and (max-width: 768px) {
  .backToHomeLink{
    margin: 1vh 12vw 15vh 12vw;
  }
}
