.featuredHeader { 
    margin: 1vh Max(2vw, calc(50% - 450px));
}

.seeAllEventsLink {
    display: block;
    font-family: 'Rubik', sans-serif;
    border-radius: 100px;
    padding: 1em;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    text-align: center;
    color: rgb(88, 50, 9);
    background: linear-gradient(52deg, rgba(255,161,30,1) 0%, rgba(240,186,106,1) 200%);
    border-style: none;
    margin: 1vh 40vw 15vh 40vw;
}

.banner {
  position: relative;
  height: 30vh;
  overflow: hidden;
  background-image: url("./banner/dogtags.png"); 
  background-size: cover;
  background-position: center;
}

.banner .talkNow {
  position: absolute;
  bottom: 0px;
  background-color: rgb(52, 52, 52);
  border-radius: 10px 10px 0px 0px;
  width: 40%;
  font-size: 75%;
  text-align: center;
  padding: 0.5rem;
  left: 50%;
  transform: translate(-50%, 0);
}

.vetsources-title {
  margin: 0;
  padding: 2%;
  color: rgb(55 29 1);
}

@media screen and (max-width: 768px) {
  .seeAllEventsLink{
    margin: 1vh 12vw 15vh 12vw;
  }
}