.vetsourceDetailsImage { 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    min-height: 27vh;
    background-image: url(https://laney.edu/veteran_affairs/wp-content/uploads/sites/147/2020/08/new-website-veterans-day-1.jpg);
}

.vetsourceTitle {
    text-align: left;
    display: block;
    margin: 0;
    padding: 0;
}

.vetsourceType {
    color: var(--vetsources-orange);
    font-weight: 400;
    font-size: 16px;
}

.vetsourceDetailsText {
    font-weight: 400;
    font-size: 16px;
}
  
.vetsourceDetailsContainer {
    margin: 5vw;
}

.squareIcon {    
    height: 3vh;
}

.iconTextSideBySide{
    display: flex;
    gap: 2vw;
    justify-content: flex-start;

}

.iconTextSideBySide p {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
}

.bar {
    background-color: var(--vetsources-orange);
    padding: 4vw;
}

