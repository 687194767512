.vetsource {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: auto;
    grid-template-rows: min-content;
    text-decoration: none;
    color: rgb(17, 14, 10);
    border-top: 1px solid #b7b1ac;
  }
  
  .vetsource > * {
    padding: 1vh;
  }
  
  .vetsource .bottom {
    grid-row: 2;
    grid-column: 1 / 3;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    flex-direction: column;
  }
  
  .vetsource .top-left h2 {
    font-size: 1.2rem;
    font-weight: 500;
  }
  
  .vetsource-type {
    display: block;
    font-family: 'Rubik', sans-serif;
    border-radius: 100px;
    padding: 1em;
    font-weight: 500;
    font-size: 0.5rem;
    color: rgb(88, 50, 9);
    background: rgb(255,161,30);
    background: var(--vetsources-orange);
    border-style: none;
  }
  
  .resource {
    background: var(--light-sea-green);
  }
  
  .event {
    background: var(--vetsources-orange);
  }
  
  .facility {
    background: var(--thistle);
  }
  
  
  .vetsource button {
    font-family: 'Rubik', sans-serif;
    border-radius: 100px;
    padding: 1em;
    font-weight: 500;
    color: rgb(88, 50, 9);
    background: rgb(255,161,30);
    background: linear-gradient(52deg, rgba(255,161,30,1) 0%, rgba(240,186,106,1) 200%);
    border-style: none;
    margin: 0 20vw 0 0;
  }
  
  .image-thumbnail {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 3px;
  }