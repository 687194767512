@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap');
body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: rgb(253, 253, 253);
  font-family: 'Rubik', sans-serif;
  text-decoration: none;
  --vetsources-orange: #ffa11e;
  --tang-blue: #2b59c3;
  --thistle: #e2cfea;
  --dark-green: #062726;
  --light-sea-green: #00b9ae;
}

h1 {
  padding: 2vw;
  text-align: center;
  margin: 1vw;
}

.scrollMenu{
  display: flex;
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollMenu::-webkit-scrollbar {
  display: none;
}

.vetsources-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 2vh 8vh;
  max-width: 900px;
  margin: auto;
  padding: 2vw;
}

.fixed-width-container{
  max-width: 900px;
  margin: auto;
}

.orange-background {
  background-color: var(--vetsources-orange);
}

@media screen and (max-width: 768px) {
  .vetsources-container {
    grid-template-columns: 1fr;
  }
}