.filterBarContainer {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-template-rows: 3fr 2fr;
  background-color: var(--vetsources-orange);
  padding: 2vh calc(50% - 450px);
  gap: 1vh 1vh;
}

.filterBarContainer.noActiveFilters{
  grid-template-rows: 1fr;
  gap: 0vh 0vh;
}

.activeFilters {
  grid-column: 1 / 3;
}

.filterOptions {
  padding: 2px;
  text-align: center;
  grid-column: 1/3;
}

.hamburgerStack {
  padding: 2px;
  font-size: 2rem;
  text-align: center;
  color: rgb(89 49 5);
}

.filterOptionBubble {
  display: flex;
  color: rgb(89 49 5);
  font-weight: 500;
  background-color: #ffa11e;
  border: 2px solid rgb(89 49 5);
  margin: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: 8px 15px 8px 15px;
  text-align: center;
  font-size: 20px;
  font-family: 'Rubik';
}

.activeFilters .activeFilterBubble {
  display: flex;
  background-color: rgb(148 86 1);
  color: rgb(251 181 83);
  border: none;
  margin: 0px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: 0px 15px 0px 15px;
  text-align: center;
  font-size: 14px;
  font-family: 'Rubik';
}

.activeFilters .activeFilterBubble::after {
  content: '\00a0 \00a0 \00a0 x';
}

.activeSearchFilters {
  padding: 0;
}

.activeSearchFilters .activeFilterBubble {
  display: flex;
  background-color: #ffa11e;
  color: #222;
  border: none;
  margin-bottom: 15px;
  padding: 1em;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  text-align: center;
  font-size: 1.0em;
  font-family: 'Rubik';
}

.activeSearchFilters .activeFilterBubble::after {
  content: '\00a0 \00a0 \00a0 x';
}

@media screen and (max-width: 768px) {
  .filterBarContainer {
    position: sticky;
    top: 0;
    display: grid;
    background-color: var(--vetsources-orange);
    padding: 2vw;
  }
}